import React from "react";
import Logo from "../../images/footer-logo.svg";
import styles from "./landers.module.scss";

const login_url = process.env.GATSBY_SV_IN_URL;

export const product = [
  {
    title: "Screening",
    links: [
      {
        a: "ID Verification",
        href: "/screenings/identity-verification/",
      },
      {
        a: "Address Verification",
        href: "/screenings/address-verification/",
      },
      {
        a: "Employment Verification",
        href: "/screenings/employment-verification/",
      },
      {
        a: "Education Verification",
        href: "/screenings/education-verification/",
      },
      {
        a: "Criminal Records Check",
        href: "/screenings/criminal-record-check/",
      },
      {
        a: "Court Record Verification",
        href: "/screenings/court-record-check/",
      },
      {
        a: "Reference Check",
        href: "/screenings/reference-verification/",
      },
      {
        a: "Global Database Verification",
        href: "/screenings/global-database-verification/",
      },
      { a: "Credit Check", href: "/screenings/credit-check/" },
      {
        a: "Social Media Verification",
        href: "/screenings/social-media-verification/",
      },
      { a: "Drug Test Screening", href: "/screenings/drug-test-verification/" },
      {
        a: "Passport Verification",
        href: "/screenings/passport-verification/",
      },
      { a: "DIN Verification", href: "/screenings/din-verification/" },
    ],
  },
  {
    title: "API",
    links: [
      { a: "API Integration", href: "/api-integrations/" },
      {
        a: "API Documentation",
        href: "https://docs-bgv.springverify.com/#introduction",
      },
    ],
  },
  {
    title: "Security",
    links: [{ a: "Security and Compliance", href: "" }],
  },
];

export const solution = [
  {
    title: "Industry",
    links: [
      { a: "Fintech", href: "/industry/fintech/" },
      { a: "E-commerce", href: "/industry/e-commerce/" },
      { a: "Staffing", href: "/industry/staffing-agencies" },
      { a: "IT and SaaS", href: "/industry/technology" },
      { a: "Healthcare", href: "/industry/healthcare" },
    ],
  },
  {
    title: "Roles",
    links: [
      { a: "Talent Acquisition", href: "/talent-acquisition/" },
      { a: "HR", href: "/human-resources/" },
      { a: "Operations", href: "/operations/" },
      { a: "Compliance", href: "/compliance/" },
    ],
  },
  {
    title: "Size",
    links: [{ a: "Enterprises", href: "/enterprise/" }],
  },
];

export const information = [
  {
    links: [
      { a: "SpringVerify", href: "/" },
      {
        a: "Login",
        href: login_url,
      },
      {
        a: "Privacy Policy",
        href:
          "https://spring-verify-us.s3.amazonaws.com/assets/SpringRole_Privacy+Policy_updated+as+of+19-02-2020.docx.pdf",
      },
      {
        a: "Terms of Service",
        href:
          "https://springverify-assets-id.s3.amazonaws.com/Springworks-Terms-of-Service.pdf",
      },
      { a: "Press Enquiries", href: "mailto:press@springworks.in" },
      {
        a: "Work with us",
        href: "https://springworks.springrecruit.com/",
      },
      { a: "Back to Springworks", href: "https://www.springworks.in/" },
    ],
  },
];

export const resources = [
  {
    links: [
      {
        a: "Blog",
        href: "https://in.springverify.com/blog/",
      },
      {
        a: "Background Screening Guide",
        href:
          "https://www.springworks.in/blog/employee-background-check-guide/",
      },
      {
        a: "Knowledge Base",
        href: "https://springworks.tawk.help/category/springverify-india",
      },
      {
        a: "Customer Stories",
        href: "https://in.springverify.com/customer-stories/",
      },
      {
        a: "Integrations",
        href: "https://in.springverify.com/integrations/",
      },
    ],
  },
];

export const customerService = [
  {
    links: [{ a: "Ordering & Payment", href: "mailto:sales@springverify.com" }],
  },
];

export const springVerifyDigital = [
  {
    links: [
      {
        a: "Digital KYC",
        href: "https://in.springverify.com/digital-kyc/",
      },
      {
        a: "SpringVerify on Whatsapp",
        href: "https://whatsapp.springverify.com/",
      },
    ],
  },
];

export const getintouch = [
  {
    links: [
      {
        a: "Email us: sales@springverify.com",
        href: "mailto:sales@springverify.com",
      },
      {
        a: "Call us: +91 90 1990 6005",
        href: "tel:+919019906005",
      },
    ],
  },
];

export const getLinks = (arr, flex) =>
  arr.map(({ title, links }, ind) => (
    <React.Fragment key={ind}>
      {title && <b>{title}</b>}
      {links.map(({ a, href }, i) =>
        href !== "" ? (
          a === "Chat with us" ? (
            <a
              href={href}
              key={i}
              onClick={() => {
                window.Tawk_API.maximize();
              }}
            >
              {a}
            </a>
          ) : (
            <a href={href} key={i}>
              {a}
            </a>
          )
        ) : (
          <span key={i}>{a}</span>
        )
      )}
    </React.Fragment>
  ));

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.top}>
        <div className={styles.flexi}>
          <img
            data-src="https://assets-sv-in.gumlet.io/LP/logo.svg"
            className={styles.sv}
          />
          <img
            data-src="https://assets-sv-in.gumlet.io/LP/leader.svg"
            className={styles.leader}
          />
        </div>
        <div className={styles.cols}>
          <div>
            <h6>Product</h6>
            {getLinks(product)}
          </div>
          <div>
            <h6>Solution</h6>
            {getLinks(solution)}
          </div>
          <div>
            <h6>Information</h6>
            {getLinks(information)}
          </div>
          <div>
            <h6>Resources</h6>
            {getLinks(resources)}
            <h6>Customer Service</h6>
            {getLinks(customerService)}
            <h6>SpringVerify Digital</h6>
            {getLinks(springVerifyDigital)}
          </div>
          <div>
            <h6>Get in touch</h6>
            {getLinks(getintouch)}
            <h6>Office</h6>
            <p>
              No. 19, 2nd Floor, Amar Plaza,
              <br />
              Krishnanagar Industrial Layout,
              <br />
              Hosur Road, Bengaluru,
              <br />
              Karnataka, 560029
            </p>
            <h6>Follow us</h6>
            <div className={styles.social}>
              <a href="https://twitter.com/springroleinc/">
                <img data-src="https://assets-sv-in.gumlet.io/LP/twitter.svg" />
              </a>
              <a href="https://www.linkedin.com/company/springrole/">
                <img data-src="https://assets-sv-in.gumlet.io/LP/linkedin.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div>
          <h6>Subscribe to Springworks </h6>
          <p>
            Exclusive invites to webinars, highly curated
            <br />
            eBooks and access to content for remote work
            <br />
            resources picked up by our editors
          </p>
          <form
            action="https://springworks.us14.list-manage.com/subscribe?u=ca6975e5d8eb9feae8b42d901&id=d29f51722f"
            method="post"
            target="_blank"
            noValidate
          >
            <input type="text" name="EMAIL" placeholder="Your email address" />
            <button type="submit">SUBSCRIBE</button>
          </form>
        </div>
        <a href="/">
          <img src={Logo} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
