import React, { useState } from "react";
import BackIcon from "../../images/prev-enabled.svg";
import RightIcon from "../../images/right-arrow.svg";
import CloseIcon from "../../images/cancelIcon.svg";
import Fade from "react-reveal/Fade";
import {
  getLinks,
  product,
  resources,
  solution,
  springVerifyDigital,
} from "./Footer";
import styles from "./landers.module.scss";

const Header = ({ isThankYouPage }) => {
  const [mobNav, setMobNav] = useState(false);
  const [links, setLinks] = useState("");

  return (
    <>
      <header className={styles.header}>
        <a href="/">
          <img
            data-src={`https://assets-sv-in.gumlet.io/LP/logo${
              isThankYouPage ? "-blue" : ""
            }.svg`}
            alt="SpringVerify Logo"
          />
        </a>
        <div className={styles.right}>
          <div className={styles.links}>
            <span>
              <span>Product</span>{" "}
              <img data-src="https://assets-sv-in.gumlet.io/LP/down.svg" />
              <div className={styles.dropdown}>
                <div className={styles.hover}>{getLinks(product)}</div>
              </div>
            </span>
            <span>
              <span>Solution</span>{" "}
              <img data-src="https://assets-sv-in.gumlet.io/LP/down.svg" />
              <div className={styles.dropdown + " " + styles.flex}>
                <div className={styles.hover}>{getLinks(solution)}</div>
              </div>
            </span>
            <span>
              <span>Resources</span>{" "}
              <img data-src="https://assets-sv-in.gumlet.io/LP/down.svg" />
              <div className={styles.dropdown}>{getLinks(resources)}</div>
            </span>
            <span>
              <span>SpringVerify Digital</span>{" "}
              <img data-src="https://assets-sv-in.gumlet.io/LP/down.svg" />
              <div className={styles.dropdown}>
                {getLinks(springVerifyDigital)}
              </div>
            </span>
          </div>
          <div className="LoginRequest">
            <a
              href={process.env.GATSBY_SV_IN_URL}
              className={`${styles.login} ${isThankYouPage ? styles.blue : ""}`}
            >
              Login
            </a>
            <a className={styles.getStarted} href="/contact-us/">
              Request Demo
            </a>
          </div>
        </div>
        <img
          data-src={`https://assets-sv-in.gumlet.io/LP/hamburger${
            isThankYouPage ? "-blue" : ""
          }.svg`}
          className={styles.hamburger}
          onClick={() => {
            setMobNav(true);
          }}
        />
      </header>
      <Fade right duration={500} when={mobNav} collapse>
        <nav className={styles.mobNav}>
          <div className={styles.header}>
            <div>
              {links !== "" && (
                <>
                  <img
                    src={BackIcon}
                    onClick={() => {
                      setLinks("");
                    }}
                  />
                  <span>{links}</span>
                </>
              )}
            </div>
            <img
              src={CloseIcon}
              onClick={() => {
                setMobNav(false);
              }}
            />
          </div>
          <div className={styles.list}>
            {links === "Product" ? (
              getLinks(product)
            ) : links === "Solution" ? (
              getLinks(solution)
            ) : links === "Resources" ? (
              getLinks(resources)
            ) : links === "SpringVerify Digital" ? (
              getLinks(springVerifyDigital)
            ) : links === "" ? (
              <>
                <div
                  onClick={() => {
                    setLinks("Product");
                  }}
                >
                  <h6>Product</h6>
                  <img src={RightIcon} />
                </div>
                <div
                  onClick={() => {
                    setLinks("Solution");
                  }}
                >
                  <h6>Solution</h6>
                  <img src={RightIcon} />
                </div>
                <div
                  onClick={() => {
                    setLinks("Resources");
                  }}
                >
                  <h6>Resources</h6>
                  <img src={RightIcon} />
                </div>
                <div
                  onClick={() => {
                    setLinks("SpringVerify Digital");
                  }}
                >
                  <h6>SpringVerify Digital</h6>
                  <img src={RightIcon} />
                </div>
              </>
            ) : null}
          </div>
        </nav>
      </Fade>
    </>
  );
};

export default Header;
